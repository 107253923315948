import React from "react";

import Effort from "models/Effort";

import { useItemsListContext } from "components/common/ItemsListSection/ItemsListProvider";
import ItemsListToolsSection from "components/common/ItemsListSection/ItemsListToolsSection";
import Skeleton from "components/common/Skeleton";
import Truncate from "components/common/Truncate";
import Cheeps from "components/common/Cheeps";
import OnLoadMoreIndicator from "components/common/OnLoadMoreIndicator";
import { cn, isEmpty, isFarsi } from "services/UtilServices";
import DateDueCheeps from "../DateDueCheeps";
import { SingleFieldUpdatePen } from "./SingleFieldUpdateButton";
import TicketsCheepsSection from "../TicketsCheepsSection";

import { LuListTree, LuShapes, LuTag, LuTimer } from "react-icons/lu";
import { Button } from "components/ui/button";
import { Separator } from "components/ui/separator";
import MiniMembersSection from "../EachEffort/MembersSection/MiniMembersSection";
import EffortBanner from "./EffortBanner";
import { useNavigate } from "react-router-dom";

function EachCardItem({ eachItem }: { eachItem: Effort }) {
  const navigate = useNavigate();
  const {
    selecteds,
    onItemSelect,
    setSelecteds,
    onItemClick,
    onItemDelete,
    selectedActionsSection,
  } = useItemsListContext();

  return (
    <Button
      asChild
      variant={selecteds.includes(eachItem) ? "primary" : "light"}
      className={cn(
        "group relative items-start shadow flex-1 p-3 block min-h-[50px] justify-start",
        selecteds.includes(eachItem) ? "" : " border bg-card hover:bg-muted " //hover:bg-light bg-[#ffffff88]
      )}
    >
      <div
        onClick={() => {
          if (selecteds.includes(eachItem)) {
            setSelecteds(selecteds.filter((e) => e !== eachItem));
            onItemSelect?.(eachItem)?.(eachItem);
          } else if (selecteds.length > 0) {
            setSelecteds([...selecteds, eachItem]);
            onItemSelect?.(eachItem)?.(eachItem);
          } else {
            navigate(eachItem.url!);
          }
        }}
        onContextMenu={
          !(
            onItemSelect(eachItem) ||
            selectedActionsSection ||
            onItemDelete([eachItem, ...selecteds])
          )
            ? undefined
            : (e) => {
                e.preventDefault();

                if (selecteds.includes(eachItem)) {
                  setSelecteds(selecteds.filter((e) => e !== eachItem));
                  onItemSelect?.(eachItem)?.(eachItem);
                } else {
                  setSelecteds([...selecteds, eachItem]);
                  onItemSelect?.(eachItem)?.(eachItem);
                }
              }
        }
      >
        <EffortBanner
          effort={eachItem}
          className="w-full object-cover rounded-t border-b mb-2 h-48"
        />

        <div className="flex items-center w-full">
          <span className="pe-2">{eachItem.index}</span>

          <span className="text-start flex-1 flex items-center ">
            {eachItem.prefix && (
              <span className="mb-0 capitalize text-xs">
                {eachItem.prefix?.name}
              </span>
            )}
            <span
              className={cn(
                "mb-0 font-bold capitalize inline-block whitespace-normal break-all ",
                {
                  "direction-rtl": isFarsi(eachItem.title),
                }
              )}
            >
              {eachItem.title}
            </span>{" "}
            <SingleFieldUpdatePen
              className="static"
              effort={eachItem}
              fieldName="title"
            />
          </span>

          <SingleFieldUpdatePen
            className="static"
            effort={eachItem}
            fieldName="state"
          />

          <Cheeps
            color={eachItem.state === null ? "#fffff0" : eachItem.state?.color}
          >
            {eachItem.state === null ? "no state" : eachItem.state?.name}
          </Cheeps>
          <div className="ps-2"></div>

          <ItemsListToolsSection
            eachItem={eachItem}
            ellipsisResponsiveBreakPoint="always"
            deleteMessage={
              <span>
                You're about to delete "<b>{eachItem.title}</b>"!
              </span>
            }
            // ellipsisResponsiveBreakPoint={ellipsisResponsiveBreakPoint}
            // btnTextResponsiveBreakPoint={btnTextResponsiveBreakPoint}
          />
        </div>

        {(!isEmpty(eachItem.ticketsCount) ||
          !isEmpty(eachItem.tags) ||
          !isEmpty(eachItem.types)) && <Separator />}

        {(eachItem.subTasksCount && eachItem.subTasksCount > 0) ||
        (eachItem.effortActivityRecordsCount &&
          eachItem.effortActivityRecordsCount > 0) ? (
          <div className="flex flex-wrap gap-1 mb-2 ">
            {Boolean(
              eachItem.effortActivityRecordsCount &&
                eachItem.effortActivityRecordsCount > 0
            ) && (
              <div className="inline-flex items-center">
                <LuTimer />
                <div className="pt-1">
                  {eachItem.effortActivityRecordsCount}
                </div>
              </div>
            )}

            {Boolean(eachItem.subTasksCount && eachItem.subTasksCount > 0) && (
              <div className="inline-flex items-center">
                <LuListTree />
                <div className="pt-1">{eachItem.subTasksCount}</div>
              </div>
            )}
          </div>
        ) : null}

        <TicketsCheepsSection
          wrap
          needIcon
          ticketCounts={eachItem.ticketsCount}
          className="min-h-[40px] "
        >
          {eachItem.parentId && (
            <SingleFieldUpdatePen
              className="static"
              effort={eachItem}
              fieldName="tickets Count"
            />
          )}
        </TicketsCheepsSection>

        {!isEmpty(eachItem.tags) && (
          <div className="flex items-center gap-2 min-h-[40px] ">
            <LuTag className="me-1" />
            <div className="flex items-center flex-wrap gap-1 small">
              {
                // eachItem.tags === undefined ? <Skeleton /> :
                <Truncate>
                  {eachItem.tags?.map((e, i) => (
                    <Cheeps key={"eachTag" + i} borderColor={e.color}>
                      {e.name}
                    </Cheeps>
                  ))}
                </Truncate>
              }
            </div>
            <SingleFieldUpdatePen
              className="static"
              effort={eachItem}
              fieldName="tags"
            />
          </div>
        )}

        {!isEmpty(eachItem.types) && (
          <div className="flex items-center gap-2 min-h-[40px] ">
            <LuShapes className="me-1" />
            <div className="flex items-center flex-wrap gap-1 small">
              {eachItem.types === undefined ? (
                <Skeleton />
              ) : (
                <Truncate>
                  {eachItem.types?.map((e, i) => (
                    <Cheeps key={"eachTag" + i} borderColor={e.color}>
                      {e.name}
                    </Cheeps>
                  ))}
                </Truncate>
              )}
            </div>
            <SingleFieldUpdatePen
              className="static"
              effort={eachItem}
              fieldName="types"
            />
          </div>
        )}

        {(!isEmpty(eachItem.members) || eachItem.dateDue) && (
          <div className="flex flex-wrap items-center border-t pt-2 mt-2">
            <MiniMembersSection effort={eachItem} />

            <div className="flex-1"></div>

            <SingleFieldUpdatePen
              className="static"
              effort={eachItem}
              fieldName="dateDue"
            />
            <DateDueCheeps effort={eachItem} />
          </div>
        )}
      </div>
    </Button>
  );
}

export default function EffortsListGridSection({
  parentId = null,
}: {
  parentId?: number | null;
}) {
  const _context = useItemsListContext();

  const {
    data,
    // title,
    // selecteds,
    // onItemSelect,
    // setSelecteds,
    // onItemClick,
    // selectedActionsSection,
    // onItemDelete,
    onLoadMore,
    // ellipsisResponsiveBreakPoint,
    // btnTextResponsiveBreakPoint,
  } = _context;

  // const onClick = (effort: Effort) => {
  //   if (selecteds.includes(effort)) {
  //     setSelecteds(selecteds.filter((e) => e !== effort));
  //     onItemSelect?.(effort)?.(effort);
  //   } else if (selecteds.length > 0) {
  //     setSelecteds([...selecteds, effort]);
  //     onItemSelect?.(effort)?.(effort);
  //   } else {
  //     onItemClick?.(effort)?.(effort);
  //   }
  // };

  // const onContextMenu = (effort: Effort) => {
  //   if (selecteds.includes(effort)) {
  //     setSelecteds(selecteds.filter((e) => e !== effort));
  //     onItemSelect?.(effort)?.(effort);
  //   } else {
  //     setSelecteds([...selecteds, effort]);
  //     onItemSelect?.(effort)?.(effort);
  //   }
  // };

  return (
    <div className="pt-2">
      <div
        className="grid gap-2"
        style={{
          gridTemplateColumns:
            "repeat(auto-fill, minmax(min(100%, 300px), 1fr))",
        }}
      >
        {(data as Effort[])?.map((eachItem, eachItemIndex) => (
          <EachCardItem
            key={"eachItem" + eachItemIndex}
            eachItem={eachItem}
            // eachItemIndex={eachItemIndex}
            // isSelected={selecteds.includes(eachItem)}
            // onContextMenu={
            //   !(
            //     onItemSelect(eachItem) ||
            //     selectedActionsSection ||
            //     onItemDelete([eachItem, ...selecteds])
            //   )
            //     ? undefined
            //     : () => onContextMenu(eachItem)
            // }
            // onClick={() => onClick(eachItem)}
          />
        ))}

        {onLoadMore && (
          <OnLoadMoreIndicator onLoadMore={onLoadMore}>
            <Skeleton count={5} className="w-full h-full" />
          </OnLoadMoreIndicator>
        )}
      </div>
    </div>
  );
}

import React from "react";

import { Editor } from "@tinymce/tinymce-react";
import { Editor as EditorType } from "tinymce/tinymce";

import Attachment, { attachmentSchema } from "models/Attachment";

import { SelectManyImageModal } from "services/SelectImageServices";
import {
  cn,
  fileToBase64,
  getFileType,
  isEmpty,
  makeid,
} from "services/UtilServices";

import { Form, useFormContext, Input } from "./Form";
import { useModalContext } from "providers/ModalProvider";

import Skeleton from "../Skeleton";
import Validation, { ValidationType } from "./UseValidation";
import FormFieldBase from "./FormFieldBase";
import AttachmentItem from "components/pages/EachEffort/AttachmentsSection/AttachmentItem";
import { useGalleryViewContext } from "providers/GalleryViewProvider";
import { LuDownload } from "react-icons/lu";
import { useToast } from "components/ui/use-toast";
import useTextEditorUtils from "hooks/UseTextEditorUtils";
import { DialogContent } from "components/ui/dialog";
import { z } from "zod";
import useValidation from "./UseValidation";
import { useThemeContext } from "providers/ThemeProvider";

export function TextEditorValue({ value }: { value?: TextEditorType }) {
  const _galleryViewContext = useGalleryViewContext();
  const textEditorUtils = useTextEditorUtils();

  const imageUrls = textEditorUtils.getImages(value);

  const openGalleryView = (url: string) => {
    _galleryViewContext.open({
      items: imageUrls,
      initUrl: url,
    });
  };

  return (
    <div className="w-full p-0">
      <div className=" w-full flow-root whitespace-normal break-all overflow-hidden">
        {textEditorUtils.parse(value?.content, {
          onClick: (ev: React.MouseEvent<HTMLImageElement, MouseEvent>) =>
            openGalleryView(ev.currentTarget.alt),
          className: "cursor-pointer",
        })}
      </div>

      {!isEmpty(value?.attachments) && (
        <div className="border-t p-2 flex flex-wrap mt-1 gap-1 w-full">
          {value!.attachments!.map((e, i) => (
            <AttachmentItem
              key={"eachAttachment" + i}
              attachment={e}
              onClick={
                getFileType(e.name) === "image"
                  ? () => openGalleryView(e.url)
                  : undefined
              }
            />
          ))}
        </div>
      )}
    </div>
  );
}

interface Props {
  name: string;
  label?: string;
  value?: string;
  validations?: ValidationType[];
  isAdvance?: boolean;
  [rest: string]: any;
}

export interface Command {
  command: string;
  text: string;
  description?: string;
  icon?: string;
  onClick?: (cmd: Command) => void;
}

export const textEditorTypeSchema = z.object({
  content: z.string().optional(),
  attachments: attachmentSchema.array().optional(),
});

export type TextEditorType = z.infer<typeof textEditorTypeSchema>;

// export interface TextEditorType {
//   content?: string;
//   attachments?: Attachment[];
// }

export default function TextEditor(props: Props) {
  const _formContext = useFormContext();
  const _modalContext = useModalContext();
  const _themeContext = useThemeContext();

  let { name, label, value, validations, isAdvance = false, ...rest } = props;

  const { toast } = useToast();
  const textEditorUtils = useTextEditorUtils();
  const validation = useValidation();

  const [_isAdvance, _setIsAdvance] = React.useState(false);
  const [_isLoading, _setisLoading] = React.useState(false);
  const [_isDraggingAttachment, _setIsDraggingAttachment] =
    React.useState(false);

  const tinymceEditorRef = React.useRef<Editor>(null);
  const valueRef = React.useRef<TextEditorType | null | undefined>(null);

  valueRef.current = textEditorUtils.server2Dict(_formContext.data[name]);

  React.useEffect(() => {
    if (!_isLoading && valueRef.current?.content?.includes(' src=""')) {
      _setisLoading(true);

      textEditorUtils
        .downloadImages({
          content: valueRef.current.content,
        })
        .then((content) => {
          _formContext.setData({ [name]: { ...valueRef.current, content } });
          _setisLoading(false);
        })
        .catch(() => _setisLoading(false));
    }
  }, []);

  const onAddAttachments = async (files: File[]) => {
    let _srces = [];

    for (const eachFile of files) {
      if (eachFile.size < 5000000) {
        _srces.push({
          id: makeid(),
          name: eachFile.name,
          url: await fileToBase64(eachFile),
        });
      } else {
        toast({
          variant: "destructive",
          description: "File size can not be more than 5M!",
        });
      }
    }

    _formContext.setData({
      [name]: {
        ...valueRef.current,
        attachments: [...(valueRef.current?.attachments ?? []), ..._srces],
      },
    });
  };

  const onRemoveAttachments = async (attachments: Attachment[]) => {
    _formContext.setData({
      [props.name]: {
        ...valueRef.current,
        attachments: (valueRef.current?.attachments as Attachment[]).filter(
          (e) => !attachments.some((a) => e.url === a.url)
        ),
      },
    });

    return {};
  };

  const onAttachmentClick = (editor: EditorType) => {
    _modalContext.open(<SelectManyImageModal onChange={onAddAttachments} />);
  };

  const onImageClick = (editor: EditorType) => {
    _modalContext.open(
      <SelectManyImageModal
        onChange={async (files) => {
          for (const eachFile of files) {
            if (eachFile.size < 5000000) {
              const _src = await fileToBase64(eachFile);
              editor.insertContent(`<img src="${_src}" width="300" />`);
            } else {
              toast({
                variant: "destructive",
                description: "File size can not be more than 5M!",
              });
            }
          }
        }}
      />
    );
  };

  const onRecordClick = (editor: EditorType) => {
    _modalContext.open(
      <DialogContent size={"sm"} fullScreen={false} onBgClickClosable={true}>
        <div></div>
      </DialogContent>
    );
  };

  const isDraggingOnlyImages = (ev: React.DragEvent | DragEvent) => {
    const imageTypes = ["image/png", "image/gif", "image/bmp", "image/jpeg"];
    let _result = false;

    if (ev.dataTransfer && ev.dataTransfer.items) {
      _result = Array.from(ev.dataTransfer.items).every((e) =>
        imageTypes.includes(e.type)
      );
    }

    return _result;
  };

  const onDragEnter = (ev: React.DragEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    console.log(
      ev.dataTransfer,
      [...Array.from(ev.dataTransfer.items)].map((e, i) => e.type),
      [...Array.from(ev.dataTransfer.files)]
    );
    // if (
    //   ev.dataTransfer.files ||
    //   ev.dataTransfer.types.some((e) => e === "Files")
    // ) {
    //   _setIsDraggingFile(true);
    // }

    if (!isDraggingOnlyImages(ev)) {
      _setIsDraggingAttachment(true);
    }
  };

  const onDragOver = (ev: React.DragEvent | DragEvent) => {
    ev.preventDefault();
    if (!_isDraggingAttachment && !isDraggingOnlyImages(ev)) {
      _setIsDraggingAttachment(true);
    }
  };

  const onDragLeave = () => {
    if (_isDraggingAttachment) {
      _setIsDraggingAttachment(false);
    }
  };

  const onDrop = (ev: React.DragEvent) => {
    ev.preventDefault();

    let _files: File[] = [];
    if (ev.dataTransfer.items) {
      _files = [...Array.from(ev.dataTransfer.items)]
        .filter((e, i) => e.kind === "file")
        .map((e, i) => e.getAsFile())
        .filter((e, i) => e) as File[];
    } else {
      _files = [...Array.from(ev.dataTransfer.files)];
    }

    onAddAttachments(_files);
    _setIsDraggingAttachment(false);
  };

  return (
    <>
      {
        <FormFieldBase
          {...props}
          needFocus={false}
          isNullBtnActive={
            !isEmpty(valueRef.current?.content) ||
            !isEmpty(valueRef.current?.attachments)
          }
          validations={[
            ...(props.validations ?? []),
            validation.notLargeFilesInEditor(),
          ]}
        >
          {(baseProps) => (
            <div
              onDragEnter={onDragEnter}
              onDragOver={onDragOver}
              className={cn("relative rounded border p-1 is-invalid ", {
                "opacity-50 pointer-events-none": baseProps.isLoading,
              })}
            >
              {valueRef.current?.content?.includes(' src=""') !== true && (
                <Editor
                  ref={tinymceEditorRef}
                  tinymceScriptSrc="/tinymce/tinymce.min.js"
                  onDragOver={onDragOver}
                  disabled={baseProps.isLoading}
                  value={valueRef.current?.content ?? ""}
                  onEditorChange={(e) => {
                    baseProps.setData({
                      [props.name]: {
                        ...valueRef.current,
                        content: tinymceEditorRef.current?.editor?.getContent(),
                      },
                    });
                  }}
                  init={{
                    skin: _themeContext.isDark ? "CUSTOM-dark" : "CUSTOM",
                    content_css: _themeContext.isDark
                      ? "CUSTOM-dark"
                      : "CUSTOM",
                    browser_spellcheck: true,
                    max_height: 500,
                    menubar: false,
                    plugins: [
                      "lists",
                      "directionality",
                      "wordcount",
                      "table",
                      "link",
                      "autolink",
                      "codesample",
                      "fullscreen",
                      "autoresize",
                      "charmap",
                    ],
                    toolbar_mode: "sliding",
                    toolbar:
                      "undo redo | " +
                      "customInsertImg customInsertAttachment customInsertRecord | " +
                      "bold backcolor forecolor fontsize | italic removeformat | bullist numlist | " +
                      "align | rtl ltr outdent indent | link unlink | table | " +
                      "codesample charmap | fullscreen",

                    setup: (editor) => {
                      editor.ui.registry.addIcon(
                        "paperclip",
                        `<svg style="width:20px;height:20px">
                          <use xlink:href="/icons/regular.svg#paperclip"></use>
                        </svg>`
                      );

                      editor.ui.registry.addButton("customInsertImg", {
                        icon: "image",
                        onAction: (_) => {
                          onImageClick(editor);
                        },
                      });

                      editor.ui.registry.addButton("customInsertAttachment", {
                        icon: "paperclip",
                        onAction: (_) => {
                          onAttachmentClick(editor);
                        },
                      });
                    },
                  }}
                />
              )}

              {!isEmpty(valueRef.current?.attachments) && (
                <div className="border-t p-2 flex flex-wrap mt-1 gap-1">
                  {valueRef.current!.attachments!.map((e, i) => (
                    <AttachmentItem
                      key={"e" + e.id}
                      attachment={e}
                      disabled={baseProps.isLoading}
                      onDelete={async () => await onRemoveAttachments([e])}
                    />
                  ))}
                </div>
              )}

              {_isLoading && (
                <Skeleton className="w-full h-full absolute p-3 top-0" />
              )}

              {_isDraggingAttachment && (
                <div
                  onDrop={onDrop}
                  onDragOver={onDragOver}
                  onDragLeave={onDragLeave}
                  className="absolute inset-0 bg-blue-400/20 rounded flex items-center justify-center text-blue-500 "
                >
                  <span className="pe-none">
                    <LuDownload className="!text-4xl " />
                  </span>
                </div>
              )}
            </div>
          )}
        </FormFieldBase>
      }
    </>
  );
}

import React from "react";

import Tag from "models/Tag";
import User, { UserRoles } from "models/User";

import LocalStorageServices from "services/localServices/LocalStorageServices";
import useMainApi from "hooks/api/UseMainApi";

import { CurrentUserContext } from "./CurrentUserProvider";
import Dict from "models/Dict";
import Effort from "models/Effort";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";

interface MainContextProps {
  efforts: Effort[] | undefined;

  setEffort: (effort: Effort) => void;
  setEfforts: (items: React.SetStateAction<Effort[] | undefined>) => void;
  setEffortPartially: (item: Dict) => void;

  sideMenuIsMini: boolean;
  setSideMenuIsMini: (l: boolean) => void;

  effortSideMenu: string | null;
  setEffortSideMenu: (l: string | null) => void;
}

const MainContext = React.createContext({} as MainContextProps);
MainContext.displayName = "MainContext";

var isRequesting = false;

function MainProvider({ children }: { children: React.ReactNode }) {
  const currentUserContext = React.useContext(CurrentUserContext);
  const mainApi = useMainApi();
  const _effortUtils = useEffortUtils();

  const [_efforts, _setEfforts] = React.useState<Effort[] | undefined>();
  const [sideMenuIsMini, _setSideMenuIsMini] = React.useState<boolean>(false);
  const [effortSideMenu, _setEffortSideMenu] = React.useState<string | null>(
    null
  );

  React.useEffect(() => {
    LocalStorageServices.get("PROJECTS").then((r) =>
      _setEfforts(r === null ? undefined : r)
    );

    LocalStorageServices.get("SIDE_MENU_IS_MINI").then((r) =>
      _setSideMenuIsMini(r ?? false)
    );

    LocalStorageServices.get("EFFORT_SIDE_MENU").then((r) =>
      _setEffortSideMenu(r)
    );
  }, []);

  React.useEffect(() => {
    if (currentUserContext.isLoggedIn() && _efforts === undefined) {
      _init();
    } else if (!currentUserContext.isLoggedIn() && _efforts) {
      setEfforts(undefined);
    }
  }, [currentUserContext.user]);

  const init = () => {
    _setEfforts(undefined);

    // _init();
  };

  const _init = async () => {
    if (isRequesting) return;
    isRequesting = true;

    try {
      let { items } = await mainApi.all();

      if (currentUserContext.user?.role === UserRoles.USER) {
        items = items
          .filter((x: Effort) =>
            x.members?.some((a) => a.userId === currentUserContext.user!.id)
          )
          .reduce((result: Effort[], eachEffort: Effort) => {
            let subEfforts = _effortUtils.getSubEffortsDeep({
              id: eachEffort.id,
              efforts: items,
            });

            for (const eachSub of subEfforts) {
              if (!result.some((e) => e.id === eachSub.id)) {
                result.push(eachSub);
              }
            }

            return result;
          }, []);
      }

      setEfforts(items);
    } catch (e) {
      console.log(e);
    }

    isRequesting = false;
  };

  const setEfforts = (items: React.SetStateAction<Effort[] | undefined>) => {
    _setEfforts(items);
  };

  const setEffort = (effort: Effort) => {
    setEfforts((prev) =>
      prev?.map((e) => (e.id !== effort.id ? e : { ...effort }))
    );
  };

  const setSideMenuIsMini = (value: boolean) => {
    _setSideMenuIsMini(value);
    LocalStorageServices.set("SIDE_MENU_IS_MINI", value);
  };

  const setEffortSideMenu = (value: string | null) => {
    _setEffortSideMenu(value);
    LocalStorageServices.set("EFFORT_SIDE_MENU", value);
  };

  const setEffortPartially = (item: Dict) => {
    setEfforts((prev) =>
      prev?.map((e) =>
        e.id !== item.id
          ? e
          : {
              ...e,
              ...item,
            }
      )
    );
  };

  return (
    <MainContext.Provider
      value={
        {
          init,

          efforts: _efforts,
          setEfforts: setEfforts,
          setEffort,
          setEffortPartially,

          sideMenuIsMini,
          setSideMenuIsMini,

          effortSideMenu,
          setEffortSideMenu,
        } as MainContextProps
      }
    >
      {children}
    </MainContext.Provider>
  );
}

export function useMainContext() {
  const _context = React.useContext(MainContext);

  if (!_context) {
    throw new Error("cannot use MainContext outside of its provider.");
  }

  return _context;
}

export { MainContext, MainProvider };
export type { MainContextProps };

import React from "react";

import { useItemsListContext, SortDirType } from "./ItemsListProvider";

import DeleteButton from "../DeleteButton";
import MenuButton from "components/ui/menu-button";
import SearchComponent from "components/common/SearchComponent";
import { FaArrowDownShortWide, FaArrowDownWideShort } from "react-icons/fa6";
import { LuCheck, LuTrash2, LuX } from "react-icons/lu";
import { cn } from "services/UtilServices";
import {
  RESPONSIVE_KEYS,
  RESPONSIVE_HIDDEN,
  RESPONSIVE_FLEX,
  RESPONSIVE_INLINE,
} from "models/views/responsive";
import {
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from "components/ui/dropdown-menu";
import { Button } from "components/ui/button";

export default function ItemListSearchbarSection({
  actionsSection,
  bottomActionSection,
  tableModeBreakPoint = "md",
  hideEllipseBreakPoint,
  isSearchable = true,
}: {
  actionsSection?: React.ReactNode;
  bottomActionSection?: React.ReactNode;
  tableModeBreakPoint?: RESPONSIVE_KEYS;
  hideEllipseBreakPoint?: RESPONSIVE_KEYS;
  isSearchable?: boolean;
}) {
  const _context = useItemsListContext();

  const {
    data,
    title,
    selecteds,
    setSelecteds,
    selectedActionsSection,
    onSearch,
    setSearchValue,
    onSortDir,
    setSelectedSortDir,
    selectedSortDir,
    onItemDelete,
    sortableFields,
    onSortField,
    setSelectedSortFieldValue,
    selectedSortFieldValue,
  } = _context;

  const _titleField = sortableFields.find((e) => e.isTitleField);

  return (
    <div>
      <div className="flex w-full items-center flex-row-reverse">
        <div className="ms-auto flex items-center ">
          {selecteds.length === 0 ? (
            actionsSection
          ) : (
            <div className="flex items-center ">
              {selectedActionsSection?.(selecteds).map((eachItem, i) => (
                <Button
                  variant={"light"}
                  key={"selectedActionItem" + i}
                  onClick={
                    React.isValidElement(eachItem)
                      ? undefined
                      : () => {
                          if ("onClick" in eachItem && eachItem.onClick) {
                            eachItem.onClick();
                            setSelecteds([]);
                          }
                        }
                  }
                >
                  {React.isValidElement(eachItem) ? (
                    eachItem
                  ) : (
                    <>
                      {"icon" in eachItem && eachItem.icon}{" "}
                      <span className="capitalize hidden lg:inline ">
                        {"text" in eachItem && eachItem.text}
                      </span>
                    </>
                  )}
                </Button>
              ))}

              {onItemDelete(selecteds) && (
                <DeleteButton
                  className=" ms-auto"
                  onConfirm={async () => {
                    const _errors = await onItemDelete(selecteds)!(selecteds);
                    setSelecteds?.([]);

                    return _errors;
                  }}
                  message={
                    _titleField ? (
                      <span>
                        You're about to delete "
                        <b>
                          {selecteds
                            .map((e) => e[_titleField.value])
                            .join('", "')}
                        </b>
                        " in <b>"{title}"</b>!
                      </span>
                    ) : undefined
                  }
                >
                  <LuTrash2 /> <span className="hidden lg:inline">Delete</span>
                </DeleteButton>
              )}

              <div className="mx-2 flex items-center gap-2">
                <span className="text-foreground text-xs">
                  ({selecteds.length})
                </span>

                <Button
                  variant={"light"}
                  onClick={() => setSelecteds([])}
                  className="rounded-full aspect-square flex items-center justify-center border border-muted-foreground p-1 "
                >
                  <LuX className="!text-base" />
                </Button>

                {data !== undefined && selecteds.length !== data.length && (
                  <Button
                    variant={"primary"}
                    onClick={() =>
                      setSelecteds(selecteds.length === data.length ? [] : data)
                    }
                    className="rounded-full aspect-square flex items-center justify-center border p-1 "
                  >
                    <LuCheck className="!text-base" />
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>

        <div
          className={cn(
            " items-center hidden ms-3 ",
            RESPONSIVE_FLEX[tableModeBreakPoint],
            {
              "me-3": title !== undefined,
            }
          )}
        >
          {isSearchable && (
            <div className="max-w-[300px]">
              <SearchComponent
                placeholder={"Search in " + title}
                onSearch={(v) => {
                  onSearch?.(v);
                  setSearchValue(v);
                }}
              />
            </div>
          )}

          {sortableFields.length === 1 && (
            <Button
              variant={"light"}
              onClick={() => {
                onSortDir?.(-selectedSortDir as SortDirType);
                setSelectedSortDir(-selectedSortDir as SortDirType);
              }}
            >
              {selectedSortDir > 0 ? (
                <FaArrowDownShortWide />
              ) : (
                <FaArrowDownWideShort />
              )}
              <span
                className={cn("hidden", RESPONSIVE_INLINE[tableModeBreakPoint])}
              >
                {selectedSortDir > 0 ? "Asc" : "Desc"}
              </span>
            </Button>
          )}
        </div>

        <h5 className="mb-0 text-foreground">{title}</h5>

        <div
          className={
            RESPONSIVE_HIDDEN[hideEllipseBreakPoint ?? tableModeBreakPoint]
          }
        >
          <MenuButton
            stopPropagation={false}
            ellipsisResponsiveBreakPoint={"always"}
            items={[
              <div
                key={"sortDropdownItem-1"}
                className="text-center"
                onClick={() => {
                  onSortDir?.(-selectedSortDir as SortDirType);
                  setSelectedSortDir(-selectedSortDir as SortDirType);
                }}
              >
                {selectedSortDir > 0 ? (
                  <FaArrowDownShortWide className="text-xl lg:me-2" />
                ) : (
                  <FaArrowDownWideShort className="text-xl lg:me-2" />
                )}
                <span className="inline-block">
                  {selectedSortDir > 0 ? "Asc" : "Desc"}
                </span>
              </div>,

              <DropdownMenuSeparator key="DropdownMenuSeparator" />,

              <DropdownMenuLabel key="DropdownMenuLabel">
                Sort
              </DropdownMenuLabel>,

              ...sortableFields
                .filter((e) => e.isSortable !== false)
                .map((eachField, index) => (
                  <div
                    key={"sortDropdownItem" + index}
                    className={cn("flex gap-1 items-center w-full flex-1")}
                    onClick={() => {
                      onSortField?.(eachField.value);
                      setSelectedSortFieldValue(eachField.value);
                    }}
                  >
                    <span className="capitalize">{eachField.label}</span>
                    <div className="flex-1"></div>
                    {selectedSortFieldValue === eachField.value && <LuCheck />}
                  </div>
                )),
            ]}
          />
        </div>
      </div>

      {bottomActionSection && (
        <div className="flex flex-row-reverse items-center overflow-x-auto scrollbar-hide bg-light rounded mt-2">
          {bottomActionSection}
        </div>
      )}

      {isSearchable && (
        <div className={cn(" pt-2", RESPONSIVE_HIDDEN[tableModeBreakPoint])}>
          <SearchComponent
            placeholder={"Search in " + title}
            onSearch={(v) => {
              onSearch?.(v);
              setSearchValue(v);
            }}
          />
        </div>
      )}
    </div>
  );
}

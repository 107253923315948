import React from "react";

import Dict from "models/Dict";

import { useItemsListContext } from "./ItemsListProvider";

import ItemsListToolsSection from "./ItemsListToolsSection";
import OnLoadMoreIndicator from "../OnLoadMoreIndicator";
import EmptyListIndicator from "../EmptyListIndicator";
import Skeleton from "../Skeleton";
import { cn } from "services/UtilServices";
import { Button } from "components/ui/button";

export default function ItemsListCardSection<T extends Dict>({
  buildCardTitleRow,
  buildCardBodyItem = () => null,
  onLoadMoreIndicator,
  itemClassName = () => "",
}: {
  buildCardTitleRow?: (v: T, i: number) => React.ReactNode;
  buildCardBodyItem?: (v: T, i: number) => React.ReactNode;
  onLoadMoreIndicator?: React.ReactNode;
  itemClassName?: (v: T, i: number) => string;
}) {
  const {
    data,
    title,
    selecteds,
    sortableFields,
    onItemSelect,
    setSelecteds,
    onItemClick,
    onItemDelete,
    selectedActionsSection,
    onLoadMore,
    ellipsisResponsiveBreakPoint,
    btnTextResponsiveBreakPoint,
  } = useItemsListContext();

  const _titleField = sortableFields.find((e) => e.isTitleField);

  return (
    <div className="pt-2">
      {data?.map((eachItem, eachItemIndex) => (
        <Button
          asChild
          key={"eachItem" + eachItemIndex}
          variant={selecteds.includes(eachItem) ? "primary" : "light"}
          className={cn(
            "group border relative   mb-2 p-3 block break-words min-h-[50px]",
            itemClassName(eachItem, eachItemIndex),
            selecteds.includes(eachItem) ? " " : "bg-card"
          )}
        >
          <div
            onClick={() => {
              if (selecteds.includes(eachItem)) {
                setSelecteds(selecteds.filter((e) => e !== eachItem));
                onItemSelect?.(eachItem)?.(eachItem);
              } else if (selecteds.length > 0) {
                setSelecteds([...selecteds, eachItem]);
                onItemSelect?.(eachItem)?.(eachItem);
              } else {
                onItemClick?.(eachItem)?.(eachItem);
              }
            }}
            onContextMenu={
              !(
                onItemSelect(eachItem) ||
                selectedActionsSection ||
                onItemDelete([eachItem, ...selecteds])
              )
                ? undefined
                : (e) => {
                    e.preventDefault();

                    if (selecteds.includes(eachItem)) {
                      setSelecteds(selecteds.filter((e) => e !== eachItem));
                      onItemSelect?.(eachItem)?.(eachItem);
                    } else {
                      setSelecteds([...selecteds, eachItem]);
                      onItemSelect?.(eachItem)?.(eachItem);
                    }
                  }
            }
          >
            <div className="flex items-center gap-2 w-full relative py-1 pe-6">
              {buildCardTitleRow && buildCardTitleRow(eachItem, eachItemIndex)}

              <div className="absolute -right-3">
                <ItemsListToolsSection
                  eachItem={eachItem}
                  ellipsisResponsiveBreakPoint={ellipsisResponsiveBreakPoint}
                  btnTextResponsiveBreakPoint={btnTextResponsiveBreakPoint}
                  deleteMessage={
                    _titleField ? (
                      <span>
                        You're about to delete "
                        <b>{eachItem[_titleField.value]}</b>" in{" "}
                        <b>"{title}"</b>!
                      </span>
                    ) : undefined
                  }
                />
              </div>
            </div>

            {buildCardBodyItem(eachItem, eachItemIndex)}
          </div>
        </Button>
      ))}

      {onLoadMore &&
        (onLoadMoreIndicator ?? (
          <OnLoadMoreIndicator onLoadMore={onLoadMore}>
            <Skeleton count={5} className="w-full mb-1 h-24" />
          </OnLoadMoreIndicator>
        ))}

      {!onLoadMore && data?.length === 0 && (
        <EmptyListIndicator text={"No " + title + " Found!"} />
      )}
    </div>
  );
}

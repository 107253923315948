import React from "react";

import Effort from "models/Effort";
import { useEffortUtils } from "hooks/utils/UseEffortUtils";

import { useItemsListContext } from "components/common/ItemsListSection/ItemsListProvider";
import { cn, isFarsi } from "services/UtilServices";
import { useNavigate } from "react-router";
import { useMainContext } from "providers/MainProvider";
import { useEffortsContext } from "providers/EffortsProvider";
import DateDueCheeps from "components/pages/DateDueCheeps";
import { SingleFieldUpdatePen } from "../SingleFieldUpdateButton";
import TicketsCheepsSection from "components/pages/TicketsCheepsSection";
import { LuListTree, LuTimer, LuX } from "react-icons/lu";
import MenuButton from "components/ui/menu-button";
import { Button } from "components/ui/button";
import MiniMembersSection from "components/pages/EachEffort/MembersSection/MiniMembersSection";
import EffortBanner from "../EffortBanner";
import { useEffortMenuButton } from "components/pages/EachEffort/EffortMenuButton";

export default function EachCardItem({
  effortId,
  onRemove,
}: {
  effortId: number;
  onRemove?: (e: Effort) => void;
}) {
  const {
    selecteds,
    onItemSelect,
    setSelecteds,
    onItemClick,
    selectedActionsSection,
    onItemDelete,
    onItemEdit,
  } = useItemsListContext();
  const _mainContext = useMainContext();
  const _effortsContext = useEffortsContext();
  // const _modalContext = useModalContext();
  // const _tagsContext = useTagsContext();
  // const _typesContext = useTypesContext();
  // const _usersContext = useUsersContext();
  // const _statesContext = useStatesContext();
  // const _prefixsContext = usePrefixsContext();
  // const _ticketsContext = useTicketsContext();
  // const _prioritiesContext = usePrioritiesContext();
  // const _departmentsContext = useDepartmentsContext();
  // const textEditorUtils = useTextEditorUtils();
  const _effortUtils = useEffortUtils();

  const navigate = useNavigate();

  const effort = _mainContext.efforts?.find((e) => e.id === effortId);
  const effortmenuButton = useEffortMenuButton(effort?.parentId);

  const _isHome = _effortsContext.currentEffort === undefined;
  const _isProject = _effortUtils.isProject(_effortsContext.currentEffort);
  const _isTask = !_isHome && !_isProject;

  // const onSubmit = async (data: Dict) => {
  //   let _errors =
  //     "id" in data
  //       ? await _effortsContext.update(data)
  //       : await _effortsContext.create(data);

  //   if (isEmpty(_errors)) {
  //     _modalContext.dismiss();
  //   }

  //   return _errors;
  // };

  // const openCreateUpdateModal = (formData?: Dict) => {
  //   _tagsContext.getAll(effortId);
  //   _typesContext.getAll(effortId);
  //   _statesContext.getAll(effortId);
  //   _prefixsContext.getAll(effortId);
  //   _ticketsContext.getAll(effortId);
  //   _prioritiesContext.getAll(effortId);
  //   _departmentsContext.getAll(effortId);

  //   // title: _isHome
  //   //   ? "Project Information"
  //   //   : _isProject
  //   //   ? "Task Information"
  //   //   : "SubTask Information",
  //   _modalContext.open(
  //     <EffortCreateUpdateModal
  //       formData={formData}
  //       onSubmit={onSubmit}
  //       parentId={effortId}
  //     />
  //   );
  // };

  return (
    <>
      {effort && (
        <Button
          asChild
          variant={selecteds.includes(effort) ? "primary" : "light"}
          className={cn(
            "group mb-1 p-2 block shadow cursor-grab",
            selecteds.includes(effort)
              ? ""
              : "border bg-background hover:bg-muted"
          )}
        >
          <div
            id={effortId + ""}
            onClick={() => {
              if (selecteds.includes(effort)) {
                setSelecteds(selecteds.filter((e) => e !== effort));
                onItemSelect?.(effort)?.(effort);
              } else if (selecteds.length > 0) {
                setSelecteds([...selecteds, effort]);
                onItemSelect?.(effort)?.(effort);
              } else {
                navigate(_effortUtils.getUrl(effortId)!);
              }
            }}
            onContextMenu={
              !(
                onItemSelect(effortId) ||
                selectedActionsSection ||
                onItemDelete([effortId, ...selecteds])
              )
                ? undefined
                : (e) => {
                    e.preventDefault();

                    if (selecteds.includes(effortId)) {
                      setSelecteds(selecteds.filter((e) => e !== effortId));
                      onItemSelect?.(effortId)?.(effortId);
                    } else {
                      setSelecteds([...selecteds, effortId]);
                      onItemSelect?.(effortId)?.(effortId);
                    }
                  }
            }
          >
            <EffortBanner
              effort={effort}
              className="w-full object-cover rounded-t border-b mb-2 h-36"
            />

            <div className="flex items-center w-full mb-2 ">
              {/* <span className="pe-2">{ effort.url }</span> */}

              <span className="text-start">
                {effort.prefix && (
                  <span className="mb-0 capitalize !font-normal !text-xs">
                    {effort.prefix?.name} -
                  </span>
                )}{" "}
                <span
                  className={cn(
                    "mb-0 capitalize inline-block whitespace-normal break-all font-bold ",
                    {
                      "direction-rtl": isFarsi(effort.title),
                    }
                  )}
                >
                  {effort.title}
                </span>
              </span>

              <SingleFieldUpdatePen
                className="static"
                effort={effort}
                fieldName="title"
              />

              <div className="flex-1"></div>

              <div className="md:invisible group-hover:visible">
                <MenuButton
                  ellipsisResponsiveBreakPoint="always"
                  btnTextResponsiveBreakPoint="never"
                  deleteMessage={
                    <span>
                      You're about to delete "<b>{effort.title}</b>"!
                    </span>
                  }
                  // onEdit={
                  //   onItemEdit(effortId) === undefined
                  //     ? undefined
                  //     : () => openCreateUpdateModal(effort) //   : () => onItemEdit(effort)?.(effort)
                  // }
                  // onDelete={
                  //   onItemDelete([effortId]) === undefined
                  //     ? undefined
                  //     : () => onItemDelete([effortId])?.([effortId])
                  // }
                  items={[
                    {
                      icon: <LuX />,
                      text: "Remove from this column",
                      onClick: onRemove ? () => onRemove(effort) : undefined,
                    },
                    ...effortmenuButton.getItems(effort),
                  ]}
                />
              </div>
            </div>

            <TicketsCheepsSection
              ticketCounts={effort.ticketsCount}
              needIcon
              wrap
              className="mb-2"
            >
              {effort.parentId && (
                <SingleFieldUpdatePen
                  className="static"
                  effort={effort}
                  fieldName="tickets Count"
                />
              )}
            </TicketsCheepsSection>

            {(effort.subTasksCount && effort.subTasksCount > 0) ||
            (effort.effortActivityRecordsCount &&
              effort.effortActivityRecordsCount > 0) ? (
              <div className="flex flex-wrap gap-1 mb-2 ">
                {Boolean(
                  effort.effortActivityRecordsCount &&
                    effort.effortActivityRecordsCount > 0
                ) && (
                  <div className="inline-flex items-center">
                    <LuTimer />
                    <div className="pt-1">
                      {effort.effortActivityRecordsCount}
                    </div>
                  </div>
                )}

                {Boolean(effort.subTasksCount && effort.subTasksCount > 0) && (
                  <div className="inline-flex items-center">
                    <LuListTree />
                    <div className="pt-1">{effort.subTasksCount}</div>
                  </div>
                )}
              </div>
            ) : null}

            <div className="flex flex-wrap items-center border-t pt-2">
              <MiniMembersSection effort={effort} />

              <div className="flex-1"></div>

              <SingleFieldUpdatePen
                className="static"
                effort={effort}
                fieldName="dateDue"
              />
              <DateDueCheeps effort={effort} />
            </div>
          </div>
        </Button>
      )}
    </>
  );
}

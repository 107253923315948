import React from "react";

import { ItemType, useItemsListContext } from "./ItemsListProvider";

import MenuButton from "components/ui/menu-button";

import { LuCheck } from "react-icons/lu";
import { cn } from "services/UtilServices";
import { RESPONSIVE_KEYS } from "models/views/responsive";

export default function ItemsListToolsSection({
  eachItem,
  ellipsisResponsiveBreakPoint = "lg",
  btnTextResponsiveBreakPoint = "xl",
  deleteMessage,
  otherItems,
  ellipsisClassName,
}: {
  eachItem: any;
  ellipsisResponsiveBreakPoint?: RESPONSIVE_KEYS;
  btnTextResponsiveBreakPoint?: RESPONSIVE_KEYS;
  deleteMessage?: React.ReactNode;
  ellipsisClassName?: string;
  otherItems?: ItemType[] | undefined;
}) {
  const _context = useItemsListContext();

  const {
    selecteds,
    onItemSelect,
    setSelecteds,
    onItemEdit,
    onItemDelete,
    getItemActions,
    selectedActionsSection,
  } = _context;

  return (
    <div>
      {selecteds.length > 0 ? (
        <div
          className={cn(
            "rounded-full aspect-square border border-muted-foreground ms-auto flex items-center justify-center mx-2 w-5 h-5 my-1 ",
            {
              "bg-primary": selecteds.includes(eachItem),
            }
          )}
        >
          {selecteds.includes(eachItem) && (
            <LuCheck
              className={cn(
                "",
                selecteds.includes(eachItem)
                  ? "text-primary-foreground"
                  : "text-foreground"
              )}
            />
          )}
        </div>
      ) : (
        <div
          className={cn(`left-0 top-0 bottom-0 md:invisible `, {
            "group-hover:visible group-hover/tools:visible":
              selecteds.length <= 0,
          })}
        >
          <MenuButton
            stopPropagation
            deleteMessage={deleteMessage}
            ellipsisResponsiveBreakPoint={ellipsisResponsiveBreakPoint}
            btnTextResponsiveBreakPoint={btnTextResponsiveBreakPoint}
            ellipsisClassName={ellipsisClassName}
            onSelect={
              !(
                onItemSelect(eachItem) ||
                selectedActionsSection ||
                onItemDelete([eachItem, ...selecteds])
              )
                ? undefined
                : () => {
                    setSelecteds([...selecteds, eachItem]);
                    onItemSelect(eachItem)!(eachItem);
                  }
            }
            onEdit={
              onItemEdit(eachItem) === undefined
                ? undefined
                : () => onItemEdit(eachItem)?.(eachItem)
            }
            onDelete={
              onItemDelete([eachItem]) === undefined
                ? undefined
                : () => onItemDelete([eachItem])?.([eachItem])
            }
            items={[
              ...(getItemActions === undefined ? [] : getItemActions(eachItem)),
              ...(otherItems ?? []),
            ]}
          />
        </div>
      )}
    </div>
  );
}
